<template>
    <div>

        <div id="row1">
            <NavPage />
        </div>

        <div id="row2">
            <HomePage />    
        </div>

        <div id="row3">
            <FooterPage />   
        </div>

    </div>
</template>

<script>
import NavPage from './NavPage.vue'
import HomePage from './HomePage.vue'
import FooterPage from './FooterPage.vue'

export default{
    name:'IndexPage',
    components:{
        NavPage, HomePage, FooterPage
    }
}
</script>

<style scoped>
#row2{
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100%;
}
.icons{
        width: 50px;
        height: 50px;
    }
</style>