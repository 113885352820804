<template>
    <div id="footer">
        <b-container >
            <p>  <strong>C.C.API</strong>  &copy; 2024 &nbsp; <br/>  All Rights Reserved</p>
        </b-container>
    </div>
</template>

<script>
export default{
    name: 'FooterPage'
}
</script>

<style scoped>
    p{
        font-size: 13px;
        color: white;
    }
    #footer{
        background-color: #6c757d;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        margin-top: 10px;
        width: 100%;
    }
</style>