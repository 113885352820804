<template>
    <div>
        <b-navbar toggleable="md" type="dark" variant="secondary">
            <b-navbar-brand href="#">
                C.C.API
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item href="#">Home</b-nav-item>
                <b-nav-item href="#">About</b-nav-item>
                <b-nav-item href="#">Contact</b-nav-item>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
            <!-- Right aligned nav items -->
    </div>
</template>

<script>
export default{
    name: 'NavPage'
}
</script>

<style scoped>
    div{
        width: 100%;
        height: 20px;
        margin-bottom: 15px;
    }
    .b-nav-item{
        padding-top: 5px;
    }
</style>