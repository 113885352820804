<template>
    
    <div id="home">
        <div>
      <b-row>
        <div class="col-md-6" id="col-one">
          <h2>World Currency Exchange  Rates <br/> & Forex API.</h2>
          <p>Get Real-Time Exchange rates for your business and other purposes.</p>
          <b-button variant="primary">Get Started</b-button>
  
        </div>
          
          <div class="col-md-6" id="col-two">
            <div class="container-one">
              <select 
              name="first-currency" 
              id="first-currency"
              @change="fetchData()" 
              v-model="currency_one" 
              > <!-- v-model is to bind the select option with the predefined value in variable (currency_one), 
              anytime a new value is selected, the USD will be replaced by the new value  -->
                <option value="AED">AED</option>
                <option value="ARS">ARS</option>
                <option value="AUD">AUD</option>
                <option value="BGN">BGN</option>
                <option value="BRL">BRL</option>
                <option value="BSD">BSD</option>
                <option value="CAD">CAD</option>
                <option value="CHF">CHF</option>
                <option value="CLP">CLP</option>
                <option value="CNY">CNY</option>
                <option value="COP">COP</option>
                <option value="CZK">CZK</option>
                <option value="DKK">DKK</option>
                <option value="DOP">DOP</option>
                <option value="EGP">EGP</option>
                <option value="EUR">EUR</option>
                <option value="FJD">FJD</option>
                <option value="GBP">GBP</option>
                <option value="GTQ">GTQ</option>
                <option value="HKD">HKD</option>
                <option value="HRK">HRK</option>
                <option value="HUF">HUF</option>
                <option value="IDR">IDR</option>
                <option value="ILS">ILS</option>
                <option value="INR">INR</option>
                <option value="ISK">ISK</option>
                <option value="JPY">JPY</option>
                <option value="KRW">KRW</option>
                <option value="KZT">KZT</option>
                <option value="MXN">MXN</option>
                <option value="MYR">MYR</option>
                <option value="NOK">NOK</option>
                <option value="NZD">NZD</option>
                <option value="PAB">PAB</option>
                <option value="PEN">PEN</option>
                <option value="PHP">PHP</option>
                <option value="PKR">PKR</option>
                <option value="PLN">PLN</option>
                <option value="PYG">PYG</option>
                <option value="RON">RON</option>
                <option value="RUB">RUB</option>
                <option value="SAR">SAR</option>
                <option value="SEK">SEK</option>
                <option value="SGD">SGD</option>
                <option value="THB">THB</option>
                <option value="TRY">TRY</option>
                <option value="TWD">TWD</option>
                <option value="UAH">UAH</option>
                <option value="USD">USD</option>
                <option value="UYU">UYU</option>
                <option value="VND">VND</option>
                <option value="ZAR">ZAR</option>
              </select>
              <input type="number" 
              name="input-one" 
              id="input-one"
              v-model="amountOne" 
              @input="fetchData()"> 
              <!--whenever a new value is input, it runs the function (fetchData())-->
            </div>
  
            <div class="container-two">
              <b-button @click="switchValues()" variant="primary">Switch</b-button>
              <!--the curly braces is to out the values. below we outout the selected currencys and the rate.-->
              <h4 id="baseValue">1 {{ currency_one }} = {{ rate }} {{ currency_two }}</h4>
            </div>
  
            <div class="container-three">
              <select name="second_currency" 
              id="second_currency" 
              v-model="currency_two"
              @change="fetchData()">
                <option value="AED">AED</option>
                <option value="ARS">ARS</option>
                <option value="AUD">AUD</option>
                <option value="BGN">BGN</option>
                <option value="BRL">BRL</option>
                <option value="BSD">BSD</option>
                <option value="CAD">CAD</option>
                <option value="CHF">CHF</option>
                <option value="CLP">CLP</option>
                <option value="CNY">CNY</option>
                <option value="COP">COP</option>
                <option value="CZK">CZK</option>
                <option value="DKK">DKK</option>
                <option value="DOP">DOP</option>
                <option value="EGP">EGP</option>
                <option value="EUR">EUR</option>
                <option value="FJD">FJD</option>
                <option value="GBP">GBP</option>
                <option value="GTQ">GTQ</option>
                <option value="HKD">HKD</option>
                <option value="HRK">HRK</option>
                <option value="HUF">HUF</option>
                <option value="IDR">IDR</option>
                <option value="ILS">ILS</option>
                <option value="INR">INR</option>
                <option value="ISK">ISK</option>
                <option value="JPY">JPY</option>
                <option value="KRW">KRW</option>
                <option value="KZT">KZT</option>
                <option value="MXN">MXN</option>
                <option value="MYR">MYR</option>
                <option value="NOK">NOK</option>
                <option value="NZD">NZD</option>
                <option value="PAB">PAB</option>
                <option value="PEN">PEN</option>
                <option value="PHP">PHP</option>
                <option value="PKR">PKR</option>
                <option value="PLN">PLN</option>
                <option value="PYG">PYG</option>
                <option value="RON">RON</option>
                <option value="RUB">RUB</option>
                <option value="SAR">SAR</option>
                <option value="SEK">SEK</option>
                <option value="SGD">SGD</option>
                <option value="THB">THB</option>
                <option value="TRY">TRY</option>
                <option value="TWD">TWD</option>
                <option value="UAH">UAH</option>
                <option value="USD">USD</option>
                <option value="UYU">UYU</option>
                <option value="VND">VND</option>
                <option value="ZAR">ZAR</option>
              </select>
  
              <input type="number" id="amount-two" placeholder="0" 
              v-model="amountTwo" disabled>
            </div>
  
            <div class="container-four">
              <h4 id="LastlyUpdated">Lastly Updated: {{ data.time_last_update_utc }}</h4>
            </div>
          </div>
        </b-row>
    </div>

    <hr/>

    <div class="details">
                <b-row>
                    <b-col class="col-md-2">
                        <h3>FOLLOW US <br> &rarr; </h3>
                    </b-col>
                    <b-col class="col-md-2">
                        <img src="./../assets/FaceBookICON1.jpg" class="icons">
                        <img src="./../assets/InstagramICON1.png" class="icons">
                        <img src="./../assets/twitterICON3.jpeg" class="icons">
                        <img src="./../assets/linkedInICON1.png" class="icons">
                    </b-col>
                    <b-col class="col-md-2">
                        <h5>About CC API</h5>
                        <h5>Blog</h5>
                        <h5>Experience</h5>
                    </b-col>
                    <b-col class="col-md-2">
                        <h5>Privacy policy</h5>
                        <h5>Write Us</h5>
                    </b-col>
                    <b-col class="col-md-4">
                        <h5>Tel: +2348049483026</h5>
                        <h5>Tel: +2348049483026</h5>
                    </b-col>

                </b-row>
            </div>

            </div>

  </template>
  
  <script>
  
  // create a var {data} and this will return an object which will store our global values.
  export default {
    name: 'HomePage',

    data(){
      return{
        data:[], // an empty array. []
        currency_one: "USD",
        currency_two: "EUR",
        rate:"",
        amountOne: 1,
        amountTwo: 2,
      };
    },
  
    methods:{
      fetchData(){
        fetch(`https://v6.exchangerate-api.com/v6/${"46c33b24c710bb6c063a11d3"}/latest/${
            this.currency_one
          }`) 
        // currency_one is our basedValue.
        // then there will be a response (resp) in JSON format
          .then((resp) => resp.json())
          .then((data) => {
            console.log(data);
            this.data = data;
            this.rate = data.conversion_rates[this.currency_two];
            this.amountTwo = this.amountOne * this.rate.toFixed(2); 
            // when the value of first_currency is increased, it is multiplied by the rate and output the new value of second_currency.
        });
      },
  
      // this is the logic that allows the currecy_two to be updated whenever currency 1 is changed.
      switchValues() {
        const temp = this.currency_one; // const is a var where currency_one is stored
        this.currency_one = this.currency_two;
        this.currency_two = temp; 
        // this is temporary value changes according to put input value of currency_one
        this.fetchData();
      },
    },
  
  // mounted hook so that when the component is mounted on the DOM. (call the function (fetchDat))
    mounted(){
      this.fetchData();
    },
    
  }
  </script>
  
  <style>
  #home {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    /* justify-content: center; */
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-image: url(./../assets/world-map3.jpg);
  }

  .details{
    margin-top: 20px;
   }
  
  #col-one{
    padding-right: 120px;
    margin-top: 15px;
    
  }
  
  #col-two{
    padding-left: 70px;
    margin-top: 70px;
  }
  p{
    font-weight: bold;
  }
  h1 {
    color: #5fbaa7;
    font-weight: 900;
    text-align: center;
  }
  
  img {
    align-self: flex-start;
    height: 50px;
    width: 250px;
    margin-bottom: 30px;
    border-radius: 25px;
  }
  
  .container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .container-one{
    padding-bottom: 5px;
  }
  
  .container-two {
    display: flex;
    /* border: 1px solid black; */
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-content: space-evenly;
  }
  
  .container-two button {
    margin: 10px;
    padding-left: 15px;
  }
  
  .container-four{
    margin-top: 15px;
  }
  
  select {
    padding: 5px;
    font-weight: bold;
    margin: 5px;
    border: px solid rgba(21, 21, 22, 0.5);
    outline: none;
    border-radius: 5px;
    height: 40px;
  }
  
  input {
    padding: 5px;
    font-size: 23px;
    border: 2px solid rgba(21, 21, 22, 0.5);
    outline: none;
    border-radius: 3px;
    width: 350px;
    height: 50px;
    font-weight: bold;
  }
  
  #lastlyUpdated{
  
    font-weight: 500;
  }
  
  #baseValue {
    font-weight: 500;
  }
  .icons{
        width: 50px;
        height: 50px;
    }
  
  </style>
  